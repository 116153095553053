// // @flow strict
import {useEffect} from 'react';
import {navigate} from 'gatsby';

export default () => {
    useEffect(() => {
        if (!window.location.pathname.startsWith("/pages")) {
            navigate('/pages' + window.location.pathname);
        } else {
            navigate('/')
        }
    }, []);
    return null;
};

// import React from 'react';
// import Sidebar from '../components/Sidebar';
// import Layout from '../components/Layout';
// import Page from '../components/Page';
// import { useSiteMetadata } from '../hooks';
// import {Redirect} from "@reach/router";
// const NotFoundTemplate = () => {
//   const { title, subtitle } = useSiteMetadata();
//
//   return (
//       <Redirect to={`/`} />
//     // <Layout title={`Not Found - ${title}`} description={subtitle}>
//     //   <Sidebar />
//     //   <Page title="NOT FOUND">
//     //     <p>Route not found.</p>
//     //   </Page>
//     // </Layout>
//   );
// };
